@font-face {
  font-family: 'Franxfurter';
  src: url('./Franxfurter.woff')
}

@font-face {
  font-family: 'Watasuki Tech Sans';
  src: url('./WatatsukiTechSans.woff')
}

h2 {
  font-family: 'Franxfurter', sans-serif;
  font-size: calc(30px + 6vmin);
  font-weight: normal;
  margin-bottom: 10px;
}

h3 {
  font-family: 'Franxfurter', sans-serif;
  font-weight: normal;
  font-size: calc(15px + 3vmin);
}

h4 {
  font-family: 'Franxfurter', sans-serif;
  font-weight: normal;
  background-color: #3eb0fe;
  margin: 5px;
  color: #FFF;
  border-radius: 12px;
}

h4.disabled {
  background-color: #888;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #3ca3ef;
  background-image: url('./clouds.png');
  background-size: fill;
  background-repeat: no-repeat;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes logo-spin {
  0% { -webkit-transform: translateX(0%); } 
  15% { -webkit-transform: translateX(-15%) rotate(-5deg); } 
  30% { -webkit-transform: translateX(10%) rotate(3deg); } 
  45% { -webkit-transform: translateX(-5%) rotate(-3deg); } 
  60% { -webkit-transform: translateX(10%) rotate(2deg); } 
  75% { -webkit-transform: translateX(-5%) rotate(-1deg); }
  100% { -webkit-transform: translateX(0%); } 
}

@keyframes button-spin {
  0% { -webkit-transform: translateX(0%); } 
  15% { -webkit-transform: translateX(-7%) rotate(-5deg); } 
  30% { -webkit-transform: translateX(5%) rotate(3deg); } 
  45% { -webkit-transform: translateX(-2%) rotate(-3deg); } 
  60% { -webkit-transform: translateX(5%) rotate(2deg); } 
  75% { -webkit-transform: translateX(-2%) rotate(-1deg); }
  100% { -webkit-transform: translateX(0%); } 
}

.mint {
  background-color: #3ca3ef;
  background-image: url('./hills.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.social-link {
  background-color: rgba(0,0,0,0.3);
  text-decoration: none;
  font-family: "Franxfurter";
  padding: 24px;
  margin: 5px;
  color: #FFF;
  border-radius: 12px;
}

.social-link:hover {
  animation: button-spin infinite 5s linear;
}

.text-block {
  background-color: rgba(0,0,0,0.3);
  padding: 24px;
  margin: 5px;
  color: #FFF;
  width: 75%;
  border-radius: 12px;
}

.wallet-connect {
  font-family: 'Franxfurter', sans-serif;
  font-size: calc(15px + 3vmin);
  border: 0;
  font-weight: normal;
  background-color: #3eb0fe;
  margin: 5px;
  padding: 8px;
  color: #FFF;
  border-radius: 12px;
}

.sku-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 90%;
}

@media (min-width: 768px) {
  .sku-row {
    flex-direction: row;
  }
}

.sku {
  padding: 20px;
  min-width: 250px;
  cursor: pointer;
}

.sku img {
  max-width: 200px;
}

.team {
  background-color: #ff844e;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 2.5em;
}

.team-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 80%;
}

@media (min-width: 620px) {
  .team-row {
    flex-direction: row;
  }
}

.team-member {
  padding: 20px;
  width: 50%;
  text-align: center;
}

.team-member h3 {
  margin-bottom: 5px;
}

.team-duck {
  max-width: 300px;

  box-shadow: 14px 21px 10px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 14px 21px 10px -4px rgba(0, 0, 0, 0.5);
}

.faq {
  background-color: #d25ad6;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 2.5em;
}

.faq a {
  color: #41afff;
  text-decoration: none;
}

.roadmap {
  background-color: #ffb44a;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 2.5em;
}

footer {
  background-color: #3ca3ef;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 3em;
}

footer a {
  font-family: "Franxfurter";
  text-decoration: none;
  font-size: 200%;
  color: #96d3ff;
}